$viewport-tablet: 768px;
$viewport-desktop: 1280px;
$viewport-desktop-xl: 1920px;

@mixin media-tablet {
  @media (min-width: $viewport-tablet) {
    @content;
  }
}

@mixin media-tablet-max {
  @media (max-width: $viewport-tablet - 1px) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: $viewport-desktop) {
    @content;
  }
}

@mixin media-desktop-max {
  @media (max-width: $viewport-desktop - 1px) {
    @content;
  }
}

@mixin media-desktop-xl {
  @media (min-width: $viewport-desktop-xl) {
    @content;
  }
}

@mixin media-desktop-xl-max {
  @media (max-width: $viewport-desktop-xl - 1px) {
    @content;
  }
}