html {
  height: 100%;
  font-size: 62.5%;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body {
  height: 100%;

  margin: 0;
  font: 1.4rem/1.5 $font-default, $font-fallback;
  color: $color-default;
}

* {
  box-sizing: border-box;
  user-select: none;
}

#app {
  height: 100%;
}

h1, h2, h3, h4 {
  margin: 0;
}