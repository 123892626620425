@import "../../../styles/variables";
@import "../../../styles/mixins.scss";

.home {
  height: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    position: relative;
    padding: ($gap * 14) 0;

    @include media-desktop {
      padding: 0;
    }
  }

  &__head {
    margin: 0 0 ($gap * 12) 0;
  }

  &__heading {
    max-width: 28rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;

    @include media-tablet {
      max-width: initial;
    }

    @include media-desktop {
      font-size: 24px;
    }

    @include  media-desktop-xl {
      font-size: 30px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-desktop {
      flex-direction: row;
      margin: 0 0 ($gap * 20) 0;
    }

    @include media-desktop-xl {
      margin: 0 0 ($gap * 40) 0;
    }
  }

  &__button {
    padding: 0 0 ($gap * 3) 0;

    @include media-desktop {
      padding: 0 ($gap * 3);
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $color-default;

  font-family: $font-title;
  font-size: 2.4rem;
  line-height: 1;
  color: $color-white;
  border-radius: ($gap * 4);
  transition: opacity $transition-default;
  cursor: pointer;

  padding: ($gap * 10) ($gap * 4);

  &:hover {
    opacity: .8;
  }

  @include media-desktop {
    padding: ($gap * 13) ($gap * 4);
    min-width: 320px;
    font-size: 4.4rem;
    border-radius: ($gap * 4);
  }

  @include media-desktop-xl {
    padding: ($gap * 22) ($gap * 4);
    min-width: 500px;
    font-size: 6rem;
    border-radius: ($gap * 7);
  }
}