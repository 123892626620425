@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.view {
  height: inherit;

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: $color-default;

  @include media-desktop {
    background-color: initial;
  }

  &__head {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: ($gap * 4) 0;

    @include media-desktop {
      padding: ($gap * 4) 0 0 0;
      margin: 0 0 auto 0;
    }
  }

  &__heading {
    font-family: $font-title, $font-fallback;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;

    color: $color-yellow;

    @include media-desktop {
      font-size: 3rem;
    }
  }

  &__content {
    flex: 1;

    display: flex;
    flex-direction: column;

    @include media-desktop {
      background-color: $color-default;
      border-radius: ($gap * 7);
    }
  }

  &__row {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin: 0 0 ($gap * 10) 0;
    padding: 0 ($gap * 9);
    text-align: center;

    p {
      margin: 0;

      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2rem;

      color: $color-white;

      @include media-desktop {
        font-size: 3rem;
        line-height: 3.5rem;
      }

      @include media-desktop-xl {
        font-size: 3.5rem;
        line-height: 4rem;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: ($gap * 6) 0;

    @include media-desktop {
      justify-content: flex-end;
      padding: ($gap * 8) 0 ($gap * 14) 0;
    }

    @include media-desktop-xl {
      padding: ($gap * 11) 0 ($gap * 18) 0;
    }
  }
}

.button {
  padding: ($gap * 1.5) ($gap * 6);
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  text-decoration: none;

  background-color: $color-black;
  color: $color-white;
  border-radius: ($gap * 3);
  cursor: pointer;
  transition: opacity $transition-default;

  &:hover {
    opacity: .8;
  }

  &_invisible {
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
  }

  &_white {
    background-color: $color-white;
    color: $color-default;
  }

  @include media-desktop {
    padding: ($gap * 2) ($gap * 8);
    border-radius: ($gap * 4);
    font-size: 2.4rem;
    line-height: 3rem;
  }
}