@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 ($gap * 6);

    @include media-desktop {
      padding: 0 ($gap * 20);
    }
  }

  &__logo {
    width: 20rem;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    @include media-desktop {
      width: 32rem;
    }

    @include media-desktop-xl {
      width: 44rem;
    }
  }

  &__language {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    transition: opacity $transition-default;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @include media-desktop-xl {
      font-size: 3rem;
    }
  }
}
