$font-default: "Helvetica Neue";
$font-fallback: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
$font-title: "HelveticaNeue-CondensedBold";

$color-default: #004F5D;
$color-black: #272626;
$color-blue: #009BB4;
$color-yellow: #FFD230;
$color-green: #50AF32;
$color-red: #E60032;
$color-white: #ffffff;

$gap: .4rem;

$transition-default: .2s linear;