@import "../../../styles/variables";
@import "../../../styles/mixins";

.popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: ($gap * 5);

  @include media-desktop {
    position: fixed;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .7);
  }

  &__content {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ($gap * 15) ($gap * 9) ($gap * 5) ($gap * 9);

    background: $color-white;
    border: 3px solid $color-red;

    @include media-desktop {
      max-width: 876px;
    }
  }

  &__close {
    position: absolute;
    width: ($gap * 6);
    height: ($gap * 6);
    top: ($gap * 4);
    right: ($gap * 4);
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 3px;
      top: 50%;
      transform: rotate(45deg);
      background: $color-red;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 3px;
      top: 50%;
      transform: rotate(-45deg);
      background: $color-red;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
    color: $color-red;

    @include media-desktop {
      font-size: 2.4rem;
      line-height: 3rem;
    }
  }

  &__reload {
    cursor: pointer;
  
    svg {
      display: block;
      width: 146px;
      height: 146px;
    }
  }
}