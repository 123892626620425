@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.view {
  height: inherit;

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: $color-default;

  @include media-desktop {
    background-color: initial;
  }

  &__head {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: ($gap * 4) 0;

    @include media-desktop {
      padding: ($gap * 4) 0 0 0;
      margin: 0 0 auto 0;
    }
  }

  &__heading {
    font-family: $font-title, $font-fallback;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;

    color: $color-yellow;

    @include media-desktop {
      font-size: 3rem;
    }
  }

  &__content {
    flex: 1;

    display: flex;
    flex-direction: column;

    @include media-desktop {
      background-color: $color-default;
      border-radius: ($gap * 7);
    }
  }

  &__row {
    flex: 1;

    display: flex;
    flex-direction: column;

    @include media-desktop {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__left {
    padding: 0 ($gap * 9);

    @include media-desktop {
      width: 50%;
      padding: 0 ($gap * 32);
    }

    @include media-desktop-xl {
      width: 55%;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;

    color: $color-white;

    @include media-desktop {
      font-size: 3rem;
      line-height: 3.5rem;
    }

    @include media-desktop-xl {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }

  &__right {
    position: relative;
    padding: ($gap * 8) 0 0 0;
    margin: 0 ($gap * 6);

    @include media-desktop {
      width: 40%;
      padding: ($gap * 6) ($gap * 32) ($gap * 6) 0;
      margin: 0;
    }

    @include media-desktop-xl {
      width: 45%;
    }
  }

  &__thumbs {
    position: relative;
    margin: 0 auto;

    max-width: 600px;

    @include media-desktop {
      max-width: none;
      margin: 0;
    }
  }

  &__thumb {
    position: relative;
    border-radius: 100%;
    overflow: hidden;

    width: 80%;
    margin: 0 0 0 auto;

    @include media-desktop {
      width: 100%;
    }

    &::after {
      content: '';
      display: block;
      padding: 0 0 100% 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-white;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__thumb2 {
    position: absolute;
    left: -20%;
    bottom: 10%;
    width: 40%;
    border-radius: 100%;
    overflow: hidden;

    left: 0;

    @include media-desktop {
      left: -20%;
    }

    &::after {
      content: '';
      display: block;
      padding: 0 0 100% 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-white;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
      height: 60%;
      object-fit: contain;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: ($gap * 6) 0;

    @include media-desktop {
      justify-content: flex-end;
      padding: ($gap * 8) 0 ($gap * 14) 0;
    }

    @include media-desktop-xl {
      padding: ($gap * 11) 0 ($gap * 18) 0;
    }
  }
}

.button {
  padding: ($gap * 1.5) ($gap * 6);
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;

  background-color: $color-black;
  color: $color-white;
  border-radius: ($gap * 3);
  cursor: pointer;
  transition: opacity $transition-default;

  &:hover {
    opacity: .8;
  }

  &_invisible {
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
  }

  @include media-desktop {
    padding: ($gap * 2) ($gap * 8);
    border-radius: ($gap * 4);
    font-size: 2.4rem;
    line-height: 3rem;
  }
}