@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.layout {
  height: inherit;

  display: flex;
  flex-direction: column;

  &__header {
    @include media-desktop {
      margin: 0 0 ($gap * 10) 0;
    }
  }

  &__main {
    flex: 1;
  }

  &__inner {
    height: 100%;

    max-width: 1688px;
    margin: 0 auto;

    @include media-desktop {
      padding: 0 ($gap * 6);
    }
  }
}