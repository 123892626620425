@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.questions {
  height: inherit;

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: $color-default;

  @include media-desktop {
    background-color: initial;
  }

  &__head {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: ($gap * 3) 0;

    @include media-desktop {
      padding: ($gap * 4) 0 0 0;
      margin: 0 0 auto 0;
    }
  }

  &__heading {
    font-family: $font-title, $font-fallback;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1;

    color: $color-yellow;

    @include media-desktop {
      font-size: 3rem;
    }
  }

  &__content {
    flex: 1;

    display: flex;
    flex-direction: column;

    @include media-desktop {
      background-color: $color-default;
      border-radius: ($gap * 7);
    }
  }

  &__row {
    flex: 1;

    display: flex;
    flex-direction: column;

    @include media-desktop {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__left {
    padding: 0 ($gap * 9);

    @include media-desktop {
      width: 60%;
      padding: 0 ($gap * 32);
    }

    @include media-desktop-xl {
      width: 55%;
    }
  }

  &__right {
    @include media-desktop {
      width: 40%;
      padding: ($gap * 6) ($gap * 32) ($gap * 6) 0;
    }

    @include media-desktop-xl {
      width: 45%;
    }
  }

  &__question {
    position: relative;
  }

  &__list {
    margin: ($gap * 8) 0 0 0;

    @include media-desktop {
      max-width: 420px;
      margin: 0 auto;
      padding: 0 0 ($gap * 6) 0;
    }
  }

  &__item {
    padding: ($gap * 2) ($gap * 6);

    font-weight: 500;
    font-size: 2.2rem;
    line-height: 2.5rem;
    border-radius: ($gap * 2);

    background-color: $color-white;
    transition: opacity $transition-default;
    cursor: pointer;

    @include media-desktop {
      font-size: 2.4rem;
      line-height: 3.2rem;
      border-radius: ($gap * 8) 0 ($gap * 8) 0;
    }

    @include media-desktop-xl {
      font-size: 3rem;
      line-height: 3.8rem;
      border-radius: ($gap * 10) 0 ($gap * 10) 0;
    }

    &:not(:last-child) {
      margin: 0 0 ($gap * 3) 0;
    }

    span {
      margin: 0 ($gap * 2) 0 0;
    }

    &:hover {
      opacity: .8;
    }

    &_correct {
      background-color: $color-green;

      &:hover {
        opacity: 1;
      }
    }

    &_incorrect {
      background-color: $color-red;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__image {
    position: relative;
    width: 20rem;
    margin: 0 auto;

    @include media-desktop {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      padding: 0 0 100% 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-white;
      border-radius: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__thumb {
    display: none;
    position: relative;

    @include media-desktop {
      display: block;
    }

    &::after {
      content: '';
      display: block;
      padding: 0 0 100% 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-white;
      border-radius: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: ($gap * 6) 0;

    @include media-desktop {
      justify-content: flex-end;
      padding: ($gap * 8) 0 ($gap * 14) 0;
    }

    @include media-desktop-xl {
      padding: ($gap * 11) 0 ($gap * 18) 0;
    }
  }
}

.question {
  padding: ($gap * 5) 0;
  color: $color-white;

  @include media-desktop {
    padding: 0 0 ($gap * 10) 0;
  }

  &__date {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;

    margin: 0 0 ($gap * 5) 0;

    @include media-desktop {
      font-size: 3rem;
      line-height: 3.5rem;
    }

    @include media-desktop-xl {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }
  }

  &__text {
    font-size: 1.8rem;
    line-height: 2.5rem;

    span {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.5rem;
      padding: 0 $gap;

      background-color: $color-yellow;
      color: $color-default;
    }

    @include media-desktop {
      font-size: 3rem;
      line-height: 4rem;

      span {
        font-size: 3.5rem;
        line-height: 4rem;
      }
    }
  }
}

.button {
  padding: ($gap * 1.5) ($gap * 6);
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;

  background-color: $color-black;
  color: $color-white;
  border-radius: ($gap * 3);
  cursor: pointer;
  transition: opacity $transition-default;

  &:hover {
    opacity: .8;
  }

  &_invisible {
    opacity: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
  }

  @include media-desktop {
    padding: ($gap * 2) ($gap * 8);
    border-radius: ($gap * 4);
    font-size: 2.4rem;
    line-height: 3rem;
  }
}